.tournament {
  width: 100%;
  min-height: 100%;
}

.cardStyle {
  /* border: linear-gradient(to right, #29A5DE, #2B3C8E); */
  border-radius: 32px !important;
  /* background: linear-gradient(to right, #b92b27, #1565c0); */
  /* background-color: #0D0D1C; */
  /* background-image: url("/public/images/card_background.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  padding: 16px !important;
  width: 100%;
  height: fit-content;
  background-color: #848A93;
}

.avatarImage {
  width: 50px;
  height: 50px;
  margin-left: auto;
  border-radius: 100%;
}

.cardTitle {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.badgeColor {
  background-color: red !important;
}

.cardBtn {
  border: 1px solid #848A93;
}

.ViewTournament {
  width: 100%;
}

.topMargins {
  /* margin-top: 12; */
  margin-top: 12 !important;
  height: 100%;
}

.beforeTabs {
  background-color: #848A93;
  border-radius: 25px;
  /* margin-top: 6px; */
  padding: 8px !important;
  overflow: auto;
  white-space: nowrap;
}

.panelBtn {
  border-radius: 25px;
  padding: 15px 25px !important;
  font-weight: 500;
  font-size: medium;
}

.orangeBg {
  background: linear-gradient(90deg,
      rgba(251, 190, 33, 1) 0%,
      rgba(224, 147, 31, 1) 35%);
  /* padding: 25px 30px !important; */
}

.blueGradiant {
  background: linear-gradient(123.9deg, #29a5de 24.41%, #2b3c8e 94.01%);
}

.description {
  font-size: large;
}

td {
  padding: 10px;
}

/* .customSelect{
background-color: #222232;
border: 1px solid #48556F;
border-radius: 18px;
color: white;
}

.customSelect option {
  color: white;
  border: none;
  padding : 5px;
} */

/* DROP DOWN CSS CREDIT CARD */
/* The container <div> - needed to position the dropdown content */
.dropdown {
  width: 100%;
  background: ;
  border: 1px solid #48556f;
  border-radius: 18px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Style The Dropdown Button */
.dropBtn {
  width: 100%;
  border: 1px solid #48556f;
  background-color: #222232;
  border-radius: 18px;
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  /* display: none; */
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  padding: 8px;
  border: 1px solid #48556f;
  border-radius: 18px;
  overflow-y: auto; 
  max-height: 360px;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.teamAvatar {
  width: 55px !important;
  height: 55px !important;
  margin-right: 10px;
  border-radius: 100%;
}

.datePicker {
  background-color: #222232 !important;
  border: 1px solid #48556f !important;
  border-radius: 18px !important;
  color: white !important;
  font-size: 16px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  line-height: 1.4 !important;
  padding: 10px;
  margin: 5px 0px 15px 0px;
  width: 100%;
}

.datePicker::-webkit-calendar-picker-indicator {
  filter: none;
}

.teamCardContainer {
  width: 100%;
}

.counter {
  height: fit-content;
  padding: 4px 15px;
  margin-left: 10px;
  background: linear-gradient(90deg,
      rgba(251, 190, 33, 1) 0%,
      rgba(224, 147, 31, 1) 35%);
  border-radius: 18px;
  color: black;
  font-weight: bold;
}

.messageButton {
  height: fit-content;
  /* width: fit-content; */
  padding: 8px 14px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  border: none;
}

&:focus-visible,
&:focus-within {
  outline: none;
}

.icon {
  display: flex;
  margin-right: 5px;
  font-size: 1rem;
}

.modalContainer {
  padding: 10px 20px;
  width: 100%;
  max-width: 800px;
  /* height: fit-content; */
  height: 600px;
  border-radius: 18px;
  background-color: #181829;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  overflow: scroll;
}

.contentContainer {
  width: 100%;
  max-width: 800px;
  /* height: fit-content; */
  height: 600px;
  border-radius: 18px;
  background-color: #181829;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  overflow: scroll;
}

.modalContainer::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 18px;
  padding: 0.6px;
  background: linear-gradient(123.9deg, #29a5de 24.41%, #2b3c8e 94.01%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.teamInfoContainer {
  width: 100%;
  height: fit-content;
  padding: 20px;
  background: #0d0d1c;
  overflow: auto;
  border-radius: 18px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editIcon {
  font-size: 0.7rem;
  color: white;
  margin-left: 20px;
  cursor: pointer;
}

.ticketContainer {
  border: 1px solid #48556f;
  background: #222232;
}

.userContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.coachBadge {
  height: fit-content;
  /* width: fit-content; */
  padding: 8px 12px;
  margin: 0 10px;
  border-radius: 20px;
  background: linear-gradient(96.39deg, #fbbe21 6.05%, #e0931f 91.05%);
  line-height: 0.7;
  font-size: 12px;
  text-align: center;
  color: #101010;
  font-weight: 400;
}
.modalBody {
  background-color: "#181829";
}

/* MEDIA QUERY */
@media only screen and (max-width: 991px) {
  .topMargins {
    margin-top: 10px;
  }

  .topMargins>div>div>div {
    padding-top: 10px;
  }

  /* .teamCardContainer{
    width: 100%;
  } */
}