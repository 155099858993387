.Ticket {
    width: 100%;
    max-width: 440px;
    margin: auto;
    height: 100%;
    padding: 20px;
    background: #0D0D1C;
    overflow: auto;
    border-radius: 18px;

    .headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .editIcon {
            font-size: .7rem;
            color: white;
            margin-left: 20px;
            cursor: pointer;
        }
    }

    .ticketContainer {
        padding: 20px 30px;
        width: 100%;
        margin: auto;
        height: fit-content;
        // max-height: 400px;
        border-radius: 18px;
        background-color: #0D0D1C;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 4;
        overflow: scroll;
        -webkit-mask: radial-gradient(30px, rgba(0, 0, 0, 0) 98%, #000) 160px -45px/320px 402.4px;

        .image {
            width: 70px;
            height: 70px;
            border-radius: 100%;
        }

        .location {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                font-size: .7rem;
                color: #246BFD;
                margin-right: 5px;
            }

        }

        hr {
            border-top: 3px dashed #2f3053 !important;
            width: 90%;
        }

        .qrImage {
            width: 100px;
            height: 100px;
        }

        .shareButton {
            padding: 12px 10px;
            font-size: 20px;
            color: white;
            background: linear-gradient(90deg, #181829 0%, #181829 35%);
            border: 1px solid #48556F;
            border-radius: 16px;
            width: 100%;

            &:focus-visible,
            &:focus-within {
                outline: none;
            }
        }
    }

}