.seed-item {
    position: relative;
    background-color: #2A54A0 !important;
}

.seed-item .hover-dates {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #48556F;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    z-index: 10;
    width: 100%;
}

.seed-item:hover .hover-dates {
    display: block;
}

hr {
    border-top: 1px solid white !important;
}