.ProtectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100dvh;
    width: 100%;

    .background {
        display: none;

        @media (max-width:991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100dvh;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.301);
        }
    }

    .menu {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: overlay;
        // padding: 0 15px;
        background-color: #2A54A0;
        transition: 0.3s;
        color: rgb(255, 255, 255);

        @media (max-width:991px) {
            position: fixed;
            left: -300px;
            z-index: 1000;
        }

        &.collapsed {
            width: 300px;
            left: 0;
        }

        &.notCollapsed {
            width: 90px;
            align-items: center;
        }

        .drawer {
            display: none;
            text-align: center;
            width: 100%;
            height: 20px;
            margin: 25px auto 50px;


            span {
                cursor: pointer;
                background-color: transparent;
                font-size: 25px;
            }

            &.collapsed {
                text-align: right;
            }

            @media (max-width:991px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            padding: 25px 12%;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.notCollapsed {
                padding: 0;

                .name,
                .profileButton {
                    visibility: hidden;
                }
            }

            img {
                // height: 60px;
                max-width: 100%;
                height: 80px;
                object-fit: contain;
                border-radius: 4px;

                &.imageNotCollapsed {
                    max-width: 100%;
                    height: 80px;
                    object-fit: contain;
                    border-radius: 4px;
                }

            }

            .name {
                width: 100%;
                font-size: 16px;
                margin: 8px 0;
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                // white-space: nowrap;
            }

            .profileButton {
                padding: 8px 25px;
                white-space: nowrap;
                color: white;
                border-radius: 5px;
                background-color: #848A93;
                font-size: 12px;
                border: none;

                &:focus-visible,
                &:focus-within {
                    outline: none;
                }
            }

            // @media (max-width : 991px) {
            //     display: none;
            // }
        }

        .item {
            width: 100%;
            height: 60px;
            margin: 2px 0;
            padding: 18px 24px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            color: white;
            // font-weight: 600;
            font-size: 14px;

            &.active {
                background-color: #ffffff;
                // border-radius: 15px;
                color: #000000;
                font-weight: 500;
                border-right: 5px solid #000000;
            }

            p {
                margin: 0 10px;
                white-space: nowrap;
            }
        }
    }

    .hamburgerButton {
        position: fixed;
        bottom: 16px; 
        left: 16px; 
        z-index: 1000; 
        background: #2A54A0; 
        border: none; 
        padding: 12px;
        border-radius: 50%; 
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    
    .hamburgerIcon {
        font-size: 20px; /* Adjust the icon size */
        color: #ffffff; /* Icon color */
    }

    .infoCard {
        border-radius: 10px;
        color: white;
        background-color: #848A93 !important;
    }
    
    .tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #48556F !important;
        background-color: #848A93 !important;
        color: #ffffff !important;
    
    
        &.active:hover,
        &.active {
            font-weight: 500;
            background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
            
            p.label {
                color: white !important;
                font-size: 1.1rem;
            }
    
            .iconContainer {
                span.icon {
                    display: none;
                }
    
                span.active_icon {
                    display: inline-flex;
                }
            }
        }
    
        &:hover {
            background: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(157,157,157,1) 35%);
            p.label {
                // color: rgb(0, 159, 43);
            }
        }
    
        .iconContainer {
            display: flex;
            flex-direction: row;
    
            span.icon,
            span.active_icon {
                margin-right: 5px;
                margin-top: 2px;
            }
    
            span.icon {
                display: inline-flex;
            }
    
            span.active_icon {
                display: none;
            }
    
        }
    
    
        p.label {
            margin: 0;
            color: white;
            font-size: 1.1rem;
            white-space: nowrap;
        }
    }


    .routeContent {
        position: relative;
        width: 100%;
        background-color: #f7f7f7;
        display: block;
        flex-direction: column;
        // min-height: 100dvh;
        overflow: hidden;

        .content {
            width: 100%;
            min-height: -webkit-fill-available;
            display: block;
            overflow: auto;
            padding: 20px;
        }


    }



}