.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    // box-shadow: 10px 5px 5px #2e71d8;

    .leftContainer {
        display: flex;
        align-items: center;

        .logo {
            width: 250px;
            height: auto;
            margin-right: 15px;
        }
    }

    .toggleButton {
        font-size: 24px;
        cursor: pointer;
        display: none; // Hide toggle button by default on larger screens
    }

    .rightContainer {
        display: flex;
        align-items: center;
        gap: 20px;

        .settingButton, .notificationButton {
            font-size: 24px;
            cursor: pointer;
        }

        .item {
            background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
            color: #fff;
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid #29A5DE;
        }

        .itemLogin {
            background: #fff;
            color: #2A54A0;
            padding: 10px 20px;
            border-radius: 5px;
            border: 3px solid #2A54A0;
        }

        &.mobileMenu {
            display: none; // Initially hidden, displayed only when the menu is toggled in mobile
        }
    }
}

// Media Queries for responsiveness
@media (max-width: 768px) {
    .Navbar {
        padding: 10px;

        .toggleButton {
            display: block; // Show hamburger toggle button on mobile screens
        }

        .rightContainer {
            display: none; // Hide rightContainer by default in mobile view

            &.mobileMenu {
                display: flex; // Show when the menu is toggled open
                flex-direction: column;
                position: absolute;
                top: 70px;
                right: 0;
                background-color: #2e71d8;
                width: 100%;
                padding: 20px;
                gap: 10px;
                z-index: 1000;
            }

            .item {
                font-size: 18px;
                padding: 10px;
                background-color: #f9f9f9;
                width: 100%;
                text-align: center;
            }
        }
    }
}
