.TournamentCard {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .imageContainer{
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px;
        justify-content: left;


        @media (max-width:768px) {
            padding: 8px 12px;
        }

        .rightArrow {
            display: flex;
            margin-left: auto;
            color: white;
            font-size: 15px;
            font-weight: 300;
        }
    }

}