.PrimeTimeLayout {
    width: 100%;
    height: 100dvh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #848A93;
    display: flex;
    flex-direction: column; /* Added to ensure the layout flows vertically (header, body, footer) */
    justify-content: space-between; /* Added to space out header, body, and footer */
    align-items: center;
    position: relative;

    @media (orientation: landscape) and (max-width: 991px) {
        padding: 1% 2%;
    }

    @media (orientation: portrait) and (max-width: 991px) {
        padding: 14% 2%;
    }

    /* Navbar Styling */
    .navbar {
        width: 100%;
        background-color: #181829;
        border-bottom: 1px solid #48556F;

        .navbar-brand {
            color: #ffffff;
        }

        .nav-link {
            color: #ffffff;
            &:hover {
                color: #ffcc00;
            }
        }
    }

    .sectionContainer {
        flex: 1; /* Ensures body takes up remaining space between header and footer */
        width: 100%;
        max-width: 1100px;
        border-radius: 22px;
        overflow: hidden;
        position: relative;

        .backButtonContainer {
            position: absolute;
            top: 20px;
            left: 20px;
            border: 1px solid #48556F;
            padding: 6px;
            font-size: 1.3rem;
            border-radius: 100%;
            display: inline-flex;
            color: white;
            cursor: pointer;

            @media (max-width: 991px) and (orientation: landscape) {
                position: relative;
                top: -5px;
                left: -5px;
                margin-right: auto;
                margin-bottom: 10px;
            }
        }

        .row {
            height: 100%;

            .col {
                padding: 20px;
                height: 100%;
                overflow-y: auto;
            }
        }
    }
    
    .viewSwitcher {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    
        button {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            margin: 0 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
    
            &:hover {
                background-color: #0056b3;
            }
        }
    
        .activeButton {
            background-color: #28a745;
        }
    }

    .redirectButton {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .redirectButton:hover {
        background-color: #0056b3;
    }

    /* Footer Styling */
    .footer {
        width: 100%;
        background-color: #181829;
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #48556F;

        p {
            margin: 0;
        }
    }
}

.tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border-radius: 25px;
    padding: 15px 45px;
    border: 1px solid #48556F !important;
    background-color: #000 !important;
    color: #ffffff !important;


    &.active:hover,
    &.active {
        font-weight: 500;
        background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
        
        p.label {
            color: white !important;
            font-size: 1.1rem;
        }

        .iconContainer {
            span.icon {
                display: none;
            }

            span.active_icon {
                display: inline-flex;
            }
        }
    }

    &:hover {
        background: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(157,157,157,1) 35%);
        p.label {
            // color: rgb(0, 159, 43);
        }
    }

    .iconContainer {
        display: flex;
        flex-direction: row;

        span.icon,
        span.active_icon {
            margin-right: 5px;
            margin-top: 2px;
        }

        span.icon {
            display: inline-flex;
        }

        span.active_icon {
            display: none;
        }

    }


    p.label {
        margin: 0;
        color: white;
        font-size: 1.1rem;
        white-space: nowrap;
    }
}
