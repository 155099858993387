.EditProfile {
    width: 100%;
    height: 100%;
    overflow: auto;
    color: #ffffff;

    .form {
        width: 100%;
        display: flex;

        .imageContainer {
            display: flex;
            position: relative;

            .fileChooseInput {
                position: absolute;
                top: 0;
                left: 0;
            }

            .label {
                user-select: none;
                cursor: pointer;
            }

            .userImage {
                max-width: 100%;
                height: 250px;
                object-fit: contain;
                border-radius: 4px;
            }

        }

        .error {
            color: "red";
            font-size: "12px";
        }




    }
}