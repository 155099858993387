.modalContainer {
    padding: 20px;
    width: 100%;
    max-width: 650px;
    height: fit-content;
    max-height: 80vh;
    border-radius: 18px;
    background-color: #0D0D1C;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10000;
    overflow: scroll;
}

.cardBtn {
    border: 1px solid #0AA7FF;
}

.datePicker{
    background-color: #ffffff !important;
    border: 1px solid #48556F !important;
    border-radius: 18px !important;
    color: rgb(0, 0, 0) !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;
}

.datePicker::-webkit-calendar-picker-indicator{
    filter: none;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text { 	
    /* content: 'Date of Birth of you kid'; */
    /* color: transparent; */
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field::before,
input[type="date"]:in-range::-webkit-datetime-edit-month-field::before,
input[type="date"]:in-range::-webkit-datetime-edit-day-field::before,
input[type="date"]:in-range::-webkit-datetime-edit-text::before {
    content: 'Your desired content here';
    color: white; /* Change the color if necessary */
    /* Add any other styling you want for the content */
}


/* input[type="date"]:before {
    content: attr(placeholder) !important;
} */



.datePicker::placeholder {
    color: white !important;
}

.datePicker:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

@media only screen and (max-width: 768px) {
    .modalContainer{
        max-width: 90vw;
    }
}
