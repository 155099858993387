.medal-img img {
    width: 50px;
    height: 50px;
}

.bracket .bracket-custom .dWYBbN {
    /* Frame 8 */

    box-sizing: border-box;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 18.22px;


    background: rgba(13, 13, 28, 0.85);
    border-radius: 10px;
    border: 1px solid #000;
}

.seed.winner {
    /* Add your individual CSS styles for the winner team */
    background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%) !important;
    /* Example background color */
    font-weight: bold;
    padding: 10px;
    /* Example font weight */
}


.bracket .bracket-custom-left .dWYBbN {
    /* Frame 8 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 18.22px; */
    gap: 18.22px;


    background: rgba(13, 13, 28, 0.85);
    border-radius: 25px;
    border: 1px solid #000;
}