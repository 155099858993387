.teamInfoContainer {
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: #0D0D1C;
    overflow: auto;
    border-radius: 18px;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.editIcon {
    font-size: .7rem;
    color: white;
    margin-left: 20px;
    cursor: pointer;
}

.ticketContainer {
    border: 1px solid #48556F;
    background: #222232;
}

.userContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.coachBadge {
    height: fit-content;
    /* width: fit-content; */
    padding: 8px 12px;
    margin: 0 10px;
    border-radius: 20px;
    background: linear-gradient(96.39deg, #FBBE21 6.05%, #E0931F 91.05%);
    line-height: .7;
    font-size: 12px;
    text-align: center;
    color: #101010;
    font-weight: 400;
}