.PrimeTimeLayout {
    width: 100%;
    min-height: 100dvh;
    background-size: 100% ;
    color: #ffffff;
    padding: 70px;

    @media (orientation: landscape) and (max-width: 991px) {
        padding: 1% 2%;
    }

    @media (orientation: portrait) and (max-width: 991px) {
        padding: 2% 2%;
    }

    /* Navbar Styling */
    .navbar {
        width: 100%;
        background-color: #181829;
        border-bottom: 1px solid #48556F;

        .navbar-brand {
            color: #ffffff;
        }

        .nav-link {
            color: #ffffff;
            &:hover {
                color: #ffcc00;
            }
        }
    }

    image {
        width: 10%;
        height: 10%;
    }

    .sectionContainer {
        border-radius: 22px;
        padding: 30px;
        background-color: #ffffff !important;
        color: black;
    }

    .gdBtn {
        background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
        color: white;
        font-weight: 500;
        border: none;

        &:hover {
            background: linear-gradient(123.9deg, #1b76a0 24.41%, #3d55cf 94.01%) !important;
            color: white;
        }
    }
    

    .redirectButton {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .redirectButton:hover {
        background-color: #0056b3;
    }

    /* Footer Styling */
    .footer {
        width: 100%;
        background-color: #181829;
        padding: 10px 0;
        text-align: center;
        color: #ffffff;
        border-top: 1px solid #48556F;
        position: fixed;

        p {
            margin: 0;
        }
    }

    .infoCard {
        border-radius: 10px;
        color: white;
        background-color: #848A93 !important;
    }

    .tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #48556F !important;
        background-color: #848A93 !important;
        color: #ffffff !important;
    
    
        &.active:hover,
        &.active {
            font-weight: 500;
            background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
            
            p.label {
                color: white !important;
                font-size: 1.1rem;
            }
    
            .iconContainer {
                span.icon {
                    display: none;
                }
    
                span.active_icon {
                    display: inline-flex;
                }
            }
        }
    
        &:hover {
            background: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(157,157,157,1) 35%);
            p.label {
                // color: rgb(0, 159, 43);
            }
        }
    
        .iconContainer {
            display: flex;
            flex-direction: row;
    
            span.icon,
            span.active_icon {
                margin-right: 5px;
                margin-top: 2px;
            }
    
            span.icon {
                display: inline-flex;
            }
    
            span.active_icon {
                display: none;
            }
    
        }
    
    
        p.label {
            margin: 0;
            color: white;
            font-size: 1.1rem;
            white-space: nowrap;
        }
    }
}
